import React from "react";
import Thanks1 from "../components/v1/pages/thanks";
import Thanks2 from "../components/v2/pages/thanks";

const Thanks = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <Thanks1 />;
  } else {
    return <Thanks2 />;
  }
};

export default Thanks;
