import React from "react";
import styles from "./thanks.module.scss";
import Layout from "../../layout/Layout";
import cx from "classnames";
import LinkButton from "../../link-button/LinkButton";
import { RouteLinks } from "../../../../utils/route-links";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../../../commons/seo";

const query = graphql`
  {
    seo: markdownRemark(
      fields: { category: { eq: "seo" }, relativePath: { eq: "seo__thanks" } }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }
  }
`;

const ThanksPage = () => {
  const queryResult = useStaticQuery(query);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={RouteLinks.thanks}
      />
      <main
        className={cx("column", styles.content)}
        data-test-e2e="thanks-container"
      >
        <h4>Thank you!</h4>
        <p>Your message has been successfully sent</p>
        <LinkButton
          route={RouteLinks.index}
          className="open"
          dataTestE2E="home-button"
        >
          Go to Homepage
        </LinkButton>
      </main>
    </Layout>
  );
};

export default ThanksPage;
